<template>
    <div class="card card-default" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">{{ $t('common.view.settings.terms.heading') }}</div>
        <div class="card-body">
            <b-list-group v-if="!loading">
                <b-list-group-item target="_blank" :href="terms.service.url">{{ terms.service.name }}</b-list-group-item>
                <b-list-group-item target="_blank" :href="terms.privacy.url">{{ terms.privacy.name }}</b-list-group-item>
                <b-list-group-item target="_blank" :href="terms.plantcontract.url">
                  {{ terms.plantcontract.name }}<br/>
                    <small>
                        <router-link :to="{name: 'Wallet'}">{{ $t('common.view.settings.terms.terms_and_conditions_final') }}</router-link>
                    </small>
                </b-list-group-item>
                <b-list-group-item target="_blank" :href="terms.affiliate.url">{{ terms.affiliate.name }}</b-list-group-item>
                <b-list-group-item target="_blank" :href="terms.agb.url">{{ terms.agb.name }}</b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        terms: {},
        loading: true
      }
    },
    beforeCreate() {
      this.$api.get('user/terms').then(response => {
          response.data.data.forEach(term => {
            this.terms[term.type] = term;
          })
          this.loading = false;
      })
    },
  }
</script>